import React, { Suspense, Fragment, useContext } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingsContext from "src/context/SettingsContext";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";

const history = createBrowserHistory();
function App() {
  const themeSeeting = useContext(SettingsContext);
  const theme = createTheme({
    theme: themeSeeting.settings.theme,
  });
  const theme1 = createTheme({
    theme: themeSeeting.settings.theme,
  });
  // console.log("theme------- theme------- ", theme.name);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SkeletonTheme
          // theme={theme1}
          baseColor={theme.name == "DARK" ? "#0000007d" : "#00000005"}
          // highlightColor={theme.name != "DARK" ? "#fafafa" : "black"}
          highlightColor={theme.name == "DARK" ? "#ffffff21" : "#00000008"}
          duration={3}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AuthContext>
              <ToastContainer />
              <Router history={history}>
                <RenderRoutes data={routes} />
              </Router>
            </AuthContext>
          </MuiPickersUtilsProvider>
        </SkeletonTheme>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
